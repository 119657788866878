.swiper-pagination {
    position: static;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 24px;

    @include breakpoint("S") {
        gap: 5px;
        padding: 12px;
    }

    & .swiper-pagination-bullet {
        display: block;
        width: 12px;
        height: 12px;
        background-color: #D9D9D9;
        opacity: 1;
        border-radius: 100px;

        @include breakpoint("S") {
            width: 6px;
            height: 6px;
        }
    }

    & .swiper-pagination-bullet-active {
        background-color: $yellow;
        width: 40px;

        @include breakpoint("S") {
            width: 20px;
        }
    }
}
