@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.appear {
    opacity: 0;
    animation: appear 0.3s ease 0s 1 forwards;
}
