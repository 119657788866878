@import "../../styles/base/vars";
@import "../../styles/base/mixins";

.header_with_pagination {
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 1.85em;

  @include breakpoint("S") {
    flex-direction: column;
    gap: 1.2em
  }

  @media (min-width: 1019px) and (min-height: 500px) and (max-height: 1200px) {
    @include adaptive-font(58, 26, 1920, 1019);
  }

  @media (min-width: 1100px) and (min-height: 500px) and (max-height: 1200px) {
    @include adaptive-font(60, 30, 1920, 1100);
  }

  @media (min-width: 1200px) and (min-height: 500px) and (max-height: 1200px) {
    @include adaptive-font(60, 32, 1920, 1200);
  }

  @media (min-width: 1300px) and (min-height: 500px) and (max-height: 1200px) {
    @include adaptive-font(56, 34, 1920, 1300);
  }
}

.title {
  width: fit-content;
  flex-shrink: 1;
  transition: 0.3s ease-in;
  transition-property:
    opacity;
}

.pagination {
  flex-shrink: 0;
  margin-top: 0.605em;

  @include breakpoint("S") {
    order: -1;
    margin-top: 0;
  }
}
