.title_1 {
    @include title;
    @include adaptive-font(88, 28);
    line-height: normal;
}

.title_2 {
    @include title;
    @include adaptive-font(64, 20);
    line-height: 1.125;

    @include breakpoint("XS") {
        line-height: 1.4;
    }
}

.title_3 {
    @include title;
    @include adaptive-font(40, 20);
    line-height: 1.2;

    @include breakpoint("XS") {
        line-height: 1.4;
    }
}

.title_4 {
    @include title;
    @include adaptive-font(36, 16);
    line-height: 1.22;
}

.title_5 {
    @include title;
    @include adaptive-font(28, 16);
    line-height: 1.14;

    @include breakpoint("XS") {
        line-height: 1.25;
    }
}

.title_6 {
    @include title;
    @include adaptive-font(24, 20);
    line-height: 1.16;
}

.title_7 {
    @include title;
    @include adaptive-font(24, 16);
    line-height: 1.16;
}
