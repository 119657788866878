@import "../../../styles/base/vars";
@import "../../../styles/base/mixins";

.pagination_item {
  position: relative;
  padding: 0.25em 0.6em;
  margin-right: 1.475em;
  @include adaptive-font(40, 18);
  line-height: 1.2;
  font-weight: 500;
  border-radius: 0.5em;
  color: rgba($text-color, 0.3);
  background-color: $grey-bg;
  transition: 0.3s $default-ease;
  transition-property:
    opacity,
    color,
    background-color;
  transition-delay: 0.5s;

  @include breakpoint("XS") {
    margin-right: 36px;
    padding: 0.5em 0.9em;
    line-height: 1;
    border-radius: 12px;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  &_active {
    background-color: $yellow;
    color: $title-color;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: calc(100% + 0.1625em);
    transform: translate(0, -100%);
    display: block;
    width: 1.15em;
    height: 4px;
    background: url('./images/lines_grey.svg') 50% 50% / contain no-repeat;
    transition: 0.3s linear;
    transition-property: background-position;
    transition-delay: 0.2s;

    @include breakpoint("XS") {
      right: calc(100% + 6px);
      width: 24px;
    }
  }

  &::after {
    background-position-x: -60px;
    background-image: url('./images/lines_yellow.svg');
  }

  &_active::before {
    background-position-x: 60px;
  }

  &_active::after {
    background-position-x: 0;
  }

  &:first-child.pagination_item_active {
    transition-delay: 1s;
  }

  &:first-child::before,
  &:first-child::after {
    display: none;
  }
}
