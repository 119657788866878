@import "../../styles/base/vars";
@import "../../styles/base/mixins";
@import "../../styles/base/keyframes";

.branches {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100dvh;
  min-height: 100vh;
  overflow: hidden;
}

.main_container {
  @include game-pad-top;
}

.items,
.branches :global(.swiper) {
  margin-top: 3.65em;

  @include breakpoint("XS") {
    margin-top: 1.4em;
  }

  @media (min-width: 1300px) and (max-height: 1200px) {
    margin-top: 2em;
  }
}

.branches :global(.swiper) {
  width: 100%;
  overflow: visible;
}

.items {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
  justify-content: center;
  align-items: stretch;

  @include breakpoint("XS") {
    grid-template-columns: 1fr;
  }
}

.branches :global(.swiper-wrapper) {
  align-items: center;
}

.branches :global(.swiper-slide) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;

  &>div {
    @include breakpoint("S") {
      flex-grow: 1;
    }

    @include breakpoint("XS") {
      flex-grow: 0;
    }
  }
}

.branches :global(.card) {
  @include breakpoint("S") {
    min-height: 300px;
  }

  @include breakpoint("XS") {
    min-height: 258px;
  }

  @include breakpoint("XXS") {
    min-height: 300px;
  }

  @media (min-width: 700px) and (min-height: 600px) and (max-height: 699px) {
    min-height: 361px;
  }

  @media (min-width: 700px) and (min-height: 699px) and (max-height: 799px) {
    min-height: 402px;
  }

  @media (min-width: 700px) and (min-height: 799px) and (max-height: 899px) {
    min-height: 427px;
  }

  @media (min-width: 700px) and (min-height: 899px) and (max-height: 999px) {
    min-height: 487px;
  }

  @media (min-width: 700px) and (min-height: 999px) and (max-height: 1099px) {
    min-height: 560px;
  }
}

.branches :global(.card .card__hidden) {
  margin-bottom: -5.4%;

  @include breakpoint("M") {
    margin-bottom: 0;
  }
}

.branches :global(.card--3 .card__hidden),
.branches :global(.card--4 .card__hidden) {
  margin-bottom: -8.6%;
}

.branches :global(.card .card__img) {
  @include breakpoint("M") {
    margin-top: 20px;
  }

  @media (min-width: 1019px) and (min-height: 600px) and (max-height: 699px) {
    height: 135px;
  }

  @media (min-width: 1019px) and (min-height: 699px) and (max-height: 799px) {
    height: 165px;
  }

  @media (min-width: 1019px) and (min-height: 799px) and (max-height: 899px) {
    height: 235px;
  }

  @media (min-width: 1019px) and (min-height: 899px) and (max-height: 999px) {
    height: 295px;
  }

  @media (min-width: 1019px) and (min-height: 999px) and (max-height: 1200px) {
    height: 200px;
  }
}

.footer_container {
  margin-top: auto;
}
