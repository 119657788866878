@import "../../styles/base/vars";
@import "../../styles/base/mixins";

.header {
    padding: 3.3% 0 3%;

    @include breakpoint("XS") {
        padding: 20px 0;
    }
}

.logos {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-left: 2px;

    @include breakpoint("S") {
        gap: 13px;
    }

    @include breakpoint("XS") {
        margin-left: 0;
        gap: 9.5px;
    }

    & img {
        flex-shrink: 0;
    }
}

.hh_logo {
    width: 43px;

    @include breakpoint("M") {
        width: 36px;
    }

    @include breakpoint("XS") {
        width: 24px;
    }
}

.x {
    width: 16px;

    @include breakpoint("XS") {
        width: 10px;
    }
}

.yandex_pro_logo {
    width: 218px;

    @include breakpoint("M") {
        width: 180px;
    }

    @include breakpoint("XS") {
        width: 123px;
    }
}
