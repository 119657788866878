@import "../../styles/base/vars";
@import "../../styles/base/mixins";

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  height: 100vh;
  overflow-x: hidden;
}

.home,
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include breakpoint("XS") {
    gap: 12px;
  }
}

.main_wrapper {
  position: relative;
  z-index: 2;
  max-width: 860px;

  @include breakpoint("S") {
    position: static;
}
}

.title_main {
  position: relative;
  width: fit-content;
  padding-right: 0.97em;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-35%);
    display: block;
    width: 0.8em;
    height: 0.8em;
    background: url("./images/sparkles.svg") 100% 0 / contain no-repeat;

    @include breakpoint("XS") {
      display: none;
    }
  }
}

.main_content {
  width: 753px;
  max-width: 100%;
  margin-top: 2.5em;
  padding: 32px 70px 44px 32px;
  background-color: $grey-bg-2;
  border-radius: 2em;

  @include breakpoint("L") {
    width: 650px;
  }

  @include breakpoint("M") {
    width: 550px;
    padding: 24px 24px 50px;
  }

  @include breakpoint("S") {
    max-width: 490px;
    width: 70%;
  }

  @include breakpoint("XS") {
    width: 100%;
    margin-top: 1.665em;
    padding: 18px 16px;
  }
}

.main_content p+p {
  margin-top: 0.8em;
}

.description_title {
  margin-top: 0.9em;

  @include breakpoint("XS") {
    margin-top: 0.8em;
  }
}

.description_text {
  margin-top: 0.8em;
}

.start_btn {
  position: relative;
  z-index: 10;
  margin-top: 32px;

  @include breakpoint("M") {
    margin-top: 20px;
  }

  @include breakpoint("XS") {
    margin-top: 15px;
  }
}

.img_city {
  position: absolute;
  right: 0;
  bottom: -9px;
  transform: translateX(7.5%);
  width: 67.8%;

  @include breakpoint("S") {
    bottom: 0;
    right: 25px;
    transform: none;
  }

  @include breakpoint("XS") {
    display: none;
  }

  & img {
    position: relative;
    z-index: 2;
    object-fit: cover;
    width: 100%;
    height: auto;
    object-position: left bottom;
  }
}

.main::before {
  content: "";
  position: absolute;
  bottom: 34px;
  left: 905px;
  display: block;
  width: 150%;
  height: 150%;
  background: url("./images/city_bg.svg") 0 100% / cover no-repeat;

  @include breakpoint("XL") {
    bottom: 24px;
  }

  @include breakpoint("L") {
    bottom: 14px;
    left: 800px;
  }

  @include breakpoint("M") {
    left: 600px;
  }

  @include breakpoint("S") {
    display: none;
  }
}

.img_girl {
  position: absolute;
  z-index: 3;
  left: 775px;
  bottom: -8px;
  width: 54.7%;
  height: 100%;

  @include breakpoint("L") {
    left: 675px;
  }

  @include breakpoint("M") {
    left: 555px;
  }

  @include breakpoint("S") {
    bottom: 0;
    left: 400px;
  }

  @include breakpoint("XS") {
    position: relative;
    left: 15px;
    bottom: auto;
    width: calc(100% + #{$pad-xs});
    height: auto;
    margin-top: -66px;
    margin-left: auto;
    margin-right: -$pad-xs;
  }

  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: left bottom;

    @include breakpoint("XS") {
      object-fit: none;
      width: 523px;
      height: 193px;
      margin-left: auto;
      object-position: left top;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 1.8%;
    right: 97%;
    display: block;
    width: 34.8%;
    height: 19%;
    background: url("./images/dust.svg") 100% 100% / contain no-repeat;

    @include breakpoint("XS") {
      display: none;
    }
  }
}

.cta {
  margin-top: 3.4%;
}

.cta_wrapper {
  display: flex;
  background-color: $grey-bg;
  border-radius: 2em;

  @include breakpoint("S") {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include breakpoint("XS") {
    padding: 20px;
  }
}

.cta_content {
  width: 46%;
  padding: 79px 0 80px 80px;

  @include breakpoint("XL") {
    padding: 72px 0 72px 72px;
  }

  @include breakpoint("L") {
    padding: 64px 0 64px 64px;
  }

  @include breakpoint("M") {
    padding: 56px 0 56px 56px;

  }

  @include breakpoint("S") {
    width: 70%;
    padding: 40px 0 20px 40px;
  }

  @include breakpoint("XS") {
    width: 100%;
    padding: 0;
  }
}

.cta_text {
  margin-top: 1.1em;
  max-width: 655px;

  @include breakpoint("S") {
    max-width: 500px;
    margin-top: 0.8em;
  }
}

.join_link {
  margin-top: 2.2em;

  @include breakpoint("XS") {
    width: calc(100% - 2px);
    max-width: 350px;
    margin: 17px 0 0;
  }

  &_dt {
    @include breakpoint("XS") {
      display: none ;
    }
  }

  &_mob {
    display: none;

    @include breakpoint("XS") {
      display: block ;
    }
  }
}

.cta_requirements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
  width: 32.7%;
  margin-left: auto;
  @include adaptive-font(40, 15.99);
  line-height: normal;
  background:
    url("./images/sparkle_1.svg") 89.8% 19.2% / 0.67em 0.67em,
    url("./images/sparkle_2.svg") 7.9% 84% / 1.4em 1.45em;
  background-repeat: no-repeat;

  @include breakpoint("S") {
    order: 10;
    width: auto;
    margin: 0 auto;
    padding: 40px 3em;
  }

  @include breakpoint("XS") {
    order: initial;
    position: relative;
    left: 50%;
    transform: translateX(-61%);
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    width: fit-content;
    margin-left: 0;
    padding: 18px 1.8em;
    background-position:
      82.6% 14px,
      1px calc(100% - 5px)
  }
}

.cta_requirement {
  width: fit-content;
  margin-left: auto;
  padding: 0.5em 0.8em;
  white-space: nowrap;
  color: $grey-dark;
  font-weight: 500;
  background-color: $white;
  border-radius: 100px;

  @include breakpoint("XS") {
    margin-left: 0;
  }

  &_dark {
    color: $white;
    background-color: $grey-dark;
  }

  &_exp {
    margin-top: -10px;
    margin-right: 14%;

    @include breakpoint("S") {
      margin-top: 0;
    }

    @include breakpoint("XS") {
      margin-right: 0;
    }
  }

  &_license {
    transform: rotate(-3.8deg);
    transform-origin: left bottom;

    @include breakpoint("XS") {
      margin-left: 3px;
    }
  }
}

.img_license {
  align-self: center;
  transform: translateY(4.3%);
  width: 21.3%;

  @include breakpoint("S") {
    width: 30%;
    margin-left: auto;
    transform: none;
  }

  @include breakpoint("XS") {
    display: none;
  }

  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: center right;
  }
}

.footer_container {
  margin-top: auto;

  @include breakpoint("XS") {
    padding-top: 12px;
  }
}
