.card {
  cursor: pointer;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 40px;
  background-color: $grey-bg;
  transition: 0.3s ease-in;
  transition-property: opacity;
  min-height: 400px;

  @include breakpoint("S") {
    min-height: 350px;
  }

  @include breakpoint("XXS") {
    min-height: 224px;
  }

  &.card--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.invisible {
    opacity: 0;
  }
}

.card--selected {
  pointer-events: none;
}

.card--outline {
  &::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border: 5px solid $title-color;
    background-color: transparent;
    transition: 0.3s linear;
    transition-property: opacity;
    pointer-events: none;
    border-radius: 45px;
    opacity: 0;

    @include breakpoint("S") {
      display: none;
    }
  }

  &.card--selected::after {
    opacity: 0 !important;
  }

  &:hover::after {
    opacity: 1;
  }
}

.card__content {
  position: relative;
  z-index: 2;
  margin-bottom: auto;
  padding: 2em 2em 0;

  @include breakpoint("L") {
    padding: 2em 24px 0;
  }

  @include breakpoint("S") {
    padding: 24px 20px 12px;
  }
}

.card__hidden {
  @include breakpoint("S") {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s cubic-bezier(0, 1.05, 0, 1);

    .card--selected & {
      max-height: 3000px;
      transition: max-height 0.8s ease-in;
    }
  }
}

.card__description {
  margin-top: 1.1em;

  .card--selected & {
    transition-delay: 0.3s;
  }

  & p+p {
    margin-top: 0.7em;
  }
}

.card__btn {
  margin-top: 1.2em;
  pointer-events: auto;

  @include breakpoint("XS") {
    margin-top: 0.8em;
    margin-left: -1px;
  }

  .card--selected & {
    transition-delay: 0.8s;
    pointer-events: auto;
  }
}

.card__description,
.card__btn {
  pointer-events: none;
  opacity: 0;
  transition: 0.8s ease-in;
  transition-property: opacity;

  @include breakpoint("S") {
    opacity: 1;
  }

  .card--selected & {
    opacity: 1;
  }
}

.card__img {
  position: relative;
  width: 100%;
  max-width: 868px;
  max-height: 461px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0 0 40px 40px;
  overflow: hidden;

  & img {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    width: auto;
    max-width: none;
    height: 100%;
  }
}

.card__img_wrapper {
  width: 100%;
  padding-top: 55.67%;
}
