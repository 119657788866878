/**
 * Variables
*/

/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
$black: #000;
$white: #fff;
$grey-dark: #21201F;
$grey-mid: #686868;
$grey-bg: #F5F4F2;
$grey-bg-2: #F2F2F5;
$yellow: #FFC806;
$yellow-hover: darken($yellow, 3%);

$title-color: $grey-dark;
$text-color: $grey-mid;

$default-ease: cubic-bezier(0.33, 1, 0.68, 1);
$default-time: 0.3s;

$default-transition: $default-time $default-ease;

$font-main: "GraphikLCG", "Arial", sans-serif;

$animation-appear: 'appear 0.3s ease 0s 1 forwards';

/* Components */
$breakpoints: (
  "XXS": 539px,
  "XS": 699px,
  "S": 1019px,
  "M": 1279px,
  "L": 1339px,
  "XL": 1600px,
  "XXL": 1920px,
  ) !default;

$pad-xxl: 112px;
$pad-xl: 90px;
$pad-l: 80px;
$pad-m: 60px;
$pad-s: 40px;
$pad-xs: 15px;
