@import "../../styles/base/vars";
@import "../../styles/base/mixins";

.btn_replay {
  @include button-reset-style;
  display: flex;
  align-items: center;
  gap: 0.8em;
  width: fit-content;
  padding: 0;
  font-weight: 600;
  @include adaptive-font(20, 14);
  line-height: 1.6;
  color: $title-color;
  background-color: transparent;
  border-radius: 0;
  transition: 0.3s ease;
  transition-property:
    color;

  @include breakpoint("XS") {
    gap: 0.55em;
    line-height: 1;
  }

  &:hover,
  &:focus {
    color: $yellow-hover;
  }

  & svg {
    width: 1.2em;

    @include breakpoint("XS") {
      width: 1.145em;
    }
  }

  & svg,
  & path {
    transition: 0.3s ease;
    transition-property: stroke;
    transform-origin: 50% 50%;
  }

  &:hover,
  &:focus {

    & svg,
    & path {
      stroke: $yellow-hover;
    }
  }
}
