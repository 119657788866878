@import "./vars";

html {
  overflow-x: initial !important;
}

body,
html {
  overflow-x: hidden;
  width: 100%;
  // height: 100dvh;
  position: relative;
  box-sizing: border-box;
  box-sizing: border-box;
  min-width: 320px;
  // scroll-behavior: smooth;
}

body {
  font-family: $font-main;
  color: $text-color;
  @include adaptive-font(20, 12);
  line-height: 1.4;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  word-break: normal;
  background: $white;

  @include breakpoint("XS") {
    line-height: 1.5;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  letter-spacing: -0.01em;
}

.wrapper {
  width: 100%;
  min-height: 100dvh;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
}

.container {
  @include container;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: "";
}

/* stylelint-disable-next-line selector-max-id */
#root {
  width: 100%;
  height: 100%;
}

img {
  display: block;
  width: 100%;
  height: auto;
  user-select: none;
}

svg {
  display: block;
}

cache {
  position: absolute;
  z-index: -1000;
  opacity: 0;
}

cache img {
  position: absolute;
}

.br_only_mobile {
  display: none;

  @media (max-width: 400px) {
    & {
      display: inline-block;
    }
  }
}

.br_only_mobile_xl {
  display: none;

  @media (max-width: 600px) {
    & {
      display: inline-block;
    }
  }
}
