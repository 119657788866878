@import "../../styles/base/vars";
@import "../../styles/base/mixins";

.footer {
  gap: 30px;
  padding: 32px 0;

  @include breakpoint("L") {
    padding: 24px 0;
  }

  @include breakpoint("M") {
    padding: 20px 0;
  }

  @include breakpoint("S") {
    padding: 16px 0;
  }

  @media (min-width: 1019px) and (min-height: 600px) and (max-height: 900px) {
    padding: 16px 0;
  }
}

.copyright {
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
}

.brand {
  position: relative;
  white-space: nowrap;
}

.brand::after {
  content: "|";
  display: inline-block;
  vertical-align: baseline;
  margin: 0 0.8em;

  @include breakpoint("XS") {
    margin: 0 0.55em;
  }
}

.hh {
  position: relative;
  align-items: center;
}

.hh_logo {
  width: 1.4em;
  margin-right: 8px;

  @include breakpoint("XS") {
    width: 16px;
    margin-top: -2px;
  }
}

.links {
  align-items: center;
  max-width: 50%;
}

.link_item {
  line-height: 1;
}

.link {
  font: inherit;
  font-family: inherit;
  color: inherit;
  line-height: 1;
  transition: color 0.3s ease-in-out;
  letter-spacing: 0.01em;

  &:hover,
  &:focus {
    color: $yellow;
  }
}
