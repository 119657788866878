@font-face {
    font-family: "GraphikLCG";
    src: url("../../fonts/GraphikLCG-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GraphikLCG";
    src: url("../../fonts/GraphikLCG-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GraphikLCG";
    src: url("../../fonts/GraphikLCG-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
