@import "../../styles/base/vars";
@import "../../styles/base/mixins";

.game {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-height: 100dvh;
  min-height: 100vh;
  overflow: hidden;
}

.game_container {
  @include game-pad-top;
}

.answers,
.game :global(.swiper) {
  margin-top: 4.2%;

  @include breakpoint("XS") {
    margin-top: 6%;
  }
}

.game :global(.swiper) {
  overflow: visible;
}

.game :global(.swiper-wrapper) {
  align-items: stretch;
}

.game :global(.swiper-slide) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

.answers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;

  @include breakpoint("L") {
    gap: 30px;
  }

  @include breakpoint("S") {
    gap: 15px;
  }
}

.game :global(.card) {
  @include breakpoint("S") {
    min-height: 350px;
  }

  @include breakpoint("XS") {
    min-height: 360px;
  }

  @media (min-width: 1019px) and (min-height: 600px) and (max-height: 699px) {
    min-height: 436px;
  }

  @media (min-width: 1019px) and (min-height: 699px) and (max-height: 799px) {
    min-height: 460px;
  }

  @media (min-width: 1019px) and (min-height: 799px) and (max-height: 899px) {
    min-height: 400px;
  }

  @media (min-width: 1019px) and (min-height: 899px) and (max-height: 999px) {
    min-height: 487px;
  }

  @media (min-width: 1019px) and (min-height: 999px) and (max-height: 1099px) {
    min-height: 560px;
  }
}

.game :global(.card .card__hidden) {
  margin-bottom: -30px;

  @include breakpoint("S") {
    margin-bottom: -20px;
  }
}
.game :global(.card .card__img) {
  @include breakpoint("M") {
    margin-top: 20px;
  }

  @media (min-width: 1019px) and (min-height: 600px) and (max-height: 699px) {
    height: 155px;
  }

  @media (min-width: 1019px) and (min-height: 700px) and (max-height: 799px) {
    height: 195px;
  }

  @media (min-width: 1019px) and (min-height: 800px) and (max-height: 999px) {
    height: 195px;
  }

  @media (min-width: 1019px) and (min-height: 1000px) and (max-height: 1099px) {
    height: 240px;
  }

  @media (min-width: 1019px) and (min-height: 1100px) and (max-height: 1200px) {
    height: 300px;
  }
}

.footer_container {
  margin-top: auto;
}
