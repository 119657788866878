.btn {
  @include button-reset-style;
  display: block;
  width: fit-content;
  padding: 0.82em 2em;
  font-weight: 600;
  @include adaptive-font(20, 14);
  text-align: center;
  line-height: 1.6;
  color: $title-color;
  background-color: $yellow;
  border-radius: 12px;
  transition: 0.3s ease-in;
  transition-property:
    opacity,
    color,
    background-color;

  @include breakpoint("M") {
    border-radius: 10px;
  }

  @include breakpoint("XS") {
    min-width: 91px;
    padding: 0.64em 1em;
    border-radius: 8px;
  }

  &_l {
    padding: 0.51em 1.44em;
    @include adaptive-font(28, 16);

    @include breakpoint("XS") {
      padding: 0.45em 1.54em;
    }
  }

  &:hover,
  &:focus {
    background-color: $yellow-hover;
  }

  &_black {
    color: $white;
    background-color: $title-color;

    &:hover,
    &:focus {
      color: $white;
      background-color: lighten($title-color, 5%);
    }
  }
}
