@import "../../styles/base/vars";
@import "../../styles/base/mixins";

.final {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-height: 100dvh;
  min-height: 100vh;
}

.final_container {
  padding-top: 2.85%; // 56px;
  padding-right: 80px;

  @include breakpoint("L") {
    @include container;
  }

  @include breakpoint("XS") {
    padding-top: 6.35%;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include breakpoint("S") {
    flex-direction: column;
    margin-top: 5px;
  }
}

.content {
  width: 43.5%;
  padding: 5.5% 0 0;

  @include breakpoint("XL") {
    width: 52%;
  }

  @include breakpoint("L") {
    width: 56%;
  }

  @include breakpoint("S") {
    width: 100%;
    padding: 0;
  }

  @media (min-width: 1019px) and (min-height: 600px) and (max-height: 700px) {
    width: 68%;
    padding-top: 20px;
  }
}

.result {
  position: relative;
  padding: 32px 44px 44px 32px;
  background-color: $grey-bg-2;
  border-radius: 2em;

  @include breakpoint("XL") {
    padding: 32px 32px 44px;
  }

  @include breakpoint("L") {
    padding: 28px;
  }

  @include breakpoint("M") {
    padding: 24px;
  }

  @include breakpoint("XS") {
    padding: 15.5px 17px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 2;
    display: block;
    background: 100% 0 / contain no-repeat;
  }

  &::before {
    top: 0;
    right: 0;
    transform: translate(-5%, -47%);
    width: 2.9em;
    height: 3em;
    background-image: url("./images/sparkle_1.svg");

    @include breakpoint("XS") {
      transform: translate(-65%, -47%);
      width: 20px;
      height: 20px;
    }
  }

  &::after {
    bottom: 0;
    left: 0;
    transform: translate(81%, 66%);
    width: 1.7em;
    height: 1.7em;
    background-image: url("./images/sparkle_2.svg");

    @include breakpoint("XS") {
      transform: translate(76%, 55%);
      width: 23px;
      height: 23.5px;
    }
  }
}

.description {
  margin-top: 0.55em;
  @include adaptive-font(28, 12);
  line-height: 1.14;

  @include breakpoint("XS") {
    margin-top: 0.65em;
    line-height: 1.5;
  }

  & p+p {
    margin-top: 0.71em;

    @include breakpoint("XS") {
      margin-top: 0.6em;
    }
  }
}

.cta {
  margin-top: 14.65%;

  @include breakpoint("S") {
    margin-top: 38px;
  }

  @media (min-width: 1019px) and (min-height: 600px) and (max-height: 800px) {
    margin-top: 40px;
  }
}

.cta_text {
  margin-top: 0.7em;
  @include adaptive-font(20, 14);

  @include breakpoint("XS") {
    line-height: 1.42;
  }

  & b {
    font-weight: 700;
  }
}

.cta_btn {
  margin-top: 1.6em;

  @include breakpoint("S") {
    margin-top: 20px;
    padding: 0.7em 1.7em 0.6em;
  }
}

.img {
  transform: translateY(-3.5%);
  width: 52.1%;

  @include breakpoint("XL") {
    width: 45%;
    transform: none;
  }

  @include breakpoint("L") {
    width: 40%;
  }

  @include breakpoint("S") {
    order: -1;
    width: 100%;
    max-width: 400px;
    margin: 0 auto -34px;
  }

  @media (min-width: 1019px) and (min-height: 600px) and (max-height: 700px) {
    width: 30%;
  }
}

.footer_container {
  margin-top: auto;
}
